import React from "react";

const AboutCard = ({ item, local }) => {
  return (
    <div
      style={{ background: `${local === "dark" ? "transparent" : item?.bg}` }}
      className="about-box2 dark:bg-transparent"
    >
      {/* <img className="w-10 h-10 object-contain  block" src={item.icon} alt="" /> */}

      <div className="flex-col content-center ">
        <h3 className="dark:text-white text-2xl font-semibold text-center pb-3">
          {item?.title}
        </h3>
        <a
        href={item?.imgDownload}
        download
        alt={item?.title}
        >
        <div className="w-full downloadbtn bg-white dark:bg-black text-[#E93B81] shadow-md">
            Descargar
              </div>
        </a>
        {/* <p className="  center leading-8 text-gray-lite dark:text-[#A6A6A6]">
        
        </p> */}
      </div>
    </div>
  );
};
export default AboutCard;
